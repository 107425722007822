let resumeData = {
    "name": "Taye Lemma",
    "role": "",
  "roleDescription": "Efficient Full Stack Developer with hands-on experience in developing and implementing applications using latest frameworks and software development life cycle. ",
    "socialLinks":[
        {
          "name":"linkedin",
        "url":"https://www.linkedin.com/in/taye-lemma-43878241/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/tayelemma",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":"http://twitter.com/lemmataye",
          "className":"fa fa-twitter"
        }
      ],
  "aboutme":"I am a Full Stack Developer with hands-on experience in developing and implementing applications using a range of technologies and programming languages. Participated in all phases of software development life cycle, coordinating with both clients and multi-cultural teams. Seeking to leverage broad development experience and hands-on technical expertise in a challenging role as a Full-stack Developer. Excel in adapting changes, taking responsiblities and team player.",
    "address":"Chicago IL",
    "website":"https://tayelemma.github.io",
    "education":[
      {
        "UniversityName":"Maharishi International University",
        "specialization":"Master in Software Development",
        "MonthOfPassing":"June",
        "YearOfPassing":"2023",
        "Achievements":"Fairfield, IO"
      },
      {
        "UniversityName":"Loyola University Chicago",
        "specialization":"MS in Pharmacology",
        "MonthOfPassing":"June",
        "YearOfPassing":"2016",
        "Achievements":"Chicago, IL"
      }
    ],
    "work":[
      {
        "CompanyName":"All States Inc",
        "specialization":"Software Developer",
        "MonthOfLeaving":"Aug",
        "YearOfLeaving":"2021",
        "Achievements":"Chicago, IL"
      },
      {
        "CompanyName":"Sol Lad",
        "specialization":"Frontend Developer",
        "MonthOfLeaving":"Jun",
        "YearOfLeaving":"2019",
        "Achievements":"Chicago, IL"
      },
      {
        "CompanyName":"Advocate Lutheran General Hospital",
        "specialization":"Clinical Research Associate",
        "MonthOfLeaving":"Aug",
        "YearOfLeaving":"2017",
        "Achievements":"Chicago, IL"
      }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname": "Angular",
        "percent": "85%"
      },
      {
        "skillname": "React",
        "percent": "90%"
      },
      {
        "skillname": "React Native",
        "percent": "80%"
      },
      {
        "skillname": "NodeJS Express JS",
        "percent": "95%"
      },
      {
        "skillname": "MongoDB",
        "percent": "90%"
      },
      {
        "skillname": "MySQL",
        "percent": "90%"
      },
      {
        "skillname": "Amazon Web Service(AWS)",
        "percent": "85%"
      },
      {
        "skillname": "HTML-CSS",
        "percent": "95%"
      },
      {
        "skillname": "BootStrap",
        "percent": "95%"
      }
  
    ],
    "portfolio":[
      {
        "name":"e-commerce (MEAN Stack)",
        "description":"SPA",
        "imgurl":"images/portfolio/coffee.jpg"
      },
      {
        "name":"Restaurant Management (React Native)",
        "description":"mobileapp",
        "imgurl":"images/portfolio/judah.jpg"
      },
      {
        "name":"Task Tracker (MEAN Stack)",
        "description":"SPA",  
        "imgurl":"images/portfolio/origami.jpg"
      },
      {
        "name":"Daily Planner (MERN Stack)",
        "description":"Database Management",
        "imgurl":"images/portfolio/toDo.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"Thanks to Upflow, we are able to gain real-time visiblility into our customer debt and follow up.",
        "name":"Sam Brook"
      },
      {
        "description":"The Elastic Beanstalk step-by-step is really great note thank you.",
        "name":"Filimon B."
      },
      {
        "description":"The Angular task-tracker app helped my team.",
        "name":"Thomas C."
      }
    ]
  }
  
  export default resumeData